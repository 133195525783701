import React, { useContext, useEffect } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import * as ContactSuccessStyles from "./../styles/contactSuccess.module.css"
import Layout from "../components/layout/layout"
import { BasketContext } from "../utils/BasketContext"
import Seo from "../components/seo"

// * Using context within a page component throws error in SSR - needed to create separate component
const CollectEmail = () => {
  const { clearBasket, basketItems, shipping } = useContext(BasketContext)
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // * send only field required to save bandwidth
    const _basketItems = basketItems.map(item => ({
      title: item.title,
      quantity: item.quantity,
      size: item.size,
    }))
    fetch(process.env.GATSBY_COLLECT_EMAIL_FUNC_URL, {
      method: "POST",
      body: JSON.stringify({
        address: shipping.address,
        basketItems: _basketItems,
      }),
    })

    clearBasket()
  }, [])

  return <></>
}

const CheckoutSuccess = () => (
  <Layout pageTitle="Checkout successful">
    <Seo noindex title="Checkout successful" />
    <CollectEmail />
    <div className={ContactSuccessStyles.container}>
      <div className={ContactSuccessStyles.headings}>
        <h2>Thank you for shopping with us!</h2>
        <h3 className={ContactSuccessStyles.subtitle}>
          You will receive an email confirmation shortly.
        </h3>

        <AniLink
          className={ContactSuccessStyles.button}
          fade
          top="entry"
          to={"/"}
          aria-label={"Go to the Home page"}
        >
          Go to home
        </AniLink>
      </div>
    </div>
  </Layout>
)

export default CheckoutSuccess
